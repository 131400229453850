<template>
  <el-dialog :visible.sync="visible" title="请选择复制到哪些分项工程" width="60%" top="5vh" v-on="$listeners" :close-on-click-modal="false" :close-on-press-escape="false">
    <vxe-table
        ref="xTable"
        show-overflow
        :row-config="{isHover: true, isCurrent: true, useKey: true}"
        :column-config="{resizable: true}"
        :tree-config="treeConfig"
        :checkbox-config="checkboxConfig"
        :data="tableData"
        size="mini"
        :height="tableHeight"
        @cell-click="cellClickEvent">
      <vxe-column type="checkbox" width="40"></vxe-column>
      <vxe-column field="name" title="名称" tree-node></vxe-column>
      <vxe-column field="code" title="编号"></vxe-column>
    </vxe-table>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="confirm()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>
<script>
// 将某个分项的单位、类型、以及构件属性批量复制给其他分项工程
import debounce from "lodash/debounce";
export default {
  name: 'WbsMultiSelectModal',
  data () {
    return {
      visible: false,
      dataForm: {
        // 此id对应的节点不可选中
        disabledId: '',
        contractId: '',
        subcontractorId: '',
      },
      checkboxConfig: {
        checkStrictly: true,
        visibleMethod: this.visibleMethod
      },
      treeConfig: {
        rowField: 'id',
        parentField: 'pid',
        showLine: true,
        lazy: true,
        hasChild: 'hasChildren',
        loadMethod: this.loadMethod,
        iconOpen: 'vxe-icon-caret-right rotate45',
        iconClose: 'vxe-icon-caret-right',
        trigger:'row',
      },
      tableData: [],
      name: '',
      fullName: '',
      tableHeight: 400,
    }
  },
  created() {
  },
  mounted() {
    this.$nextTick(()=>{
      //  表格高度设置
      this.tableHeight=window.innerHeight-200
    })
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.findChildren(0).then((data) => {
          this.tableData = data
        })
      })
    },
    cellClickEvent({ row, triggerTreeNode }) {
      // 如果是折叠/展开树的操作，就不触发点击事件
      if (triggerTreeNode) {
        return
      }
      // 如果是被禁用的节点，则不允许选中
      if (this.disabledId && this.disabledId == row.id) {
        return
      }
    },
    loadMethod({ row }) {
      return this.findChildren(row.id)
    },
    findChildren(pid) {
      let url = '/mps/wbs/children'
      return new Promise((resolve, reject) => {
        this.$http.get(
            url,
            {
              params: {
                pid: pid,
                contractId: this.dataForm.contractId,
                subcontractorId: this.dataForm.subcontractorId
              }
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            reject()
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch(() => {
          reject()
        })
      })
    },
    visibleMethod({row}) {
      return !this.dataForm.disabledId || row.id != this.dataForm.disabledId
    },
    confirm() {
      this.$emit('submit', this.$refs.xTable.getCheckboxRecords())
      this.visible = false
    },
  },
}
</script>
<style scoped>
.select-dropdown {
  height: 300px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>
