<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__role">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.name" :placeholder="$t('role.name')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('sys:role:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('sys:role:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
        <el-form-item>
          <el-alert title="角色可用于功能权限分配，还可用于设置流程审批候选人。" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" :label="$t('role.name')" align="left" sortable="custom"></el-table-column>
        <el-table-column prop="remark" :label="$t('role.remark')" sortable="custom"></el-table-column>
        <el-table-column prop="typeId" label="默认角色" width="100" align="center" sortable="custom">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.typeId && scope.row.typeId==1">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" :label="$t('role.createDate')" sortable="custom" align="center" width="160"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="190">
          <template slot-scope="scope">
            <table-button role="update" v-if="$hasPermission('sys:role:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('sys:role:delete')" @click="deleteHandle(scope.row.id)"></table-button>
            <!--<table-button type="text" size="small" @click="roleUserViewHandle(scope.row.id)">查看授权人员</table-button>-->
            <table-button icon="el-icon-setting" name="授权" type="success" v-if="$hasPermission('sys:role:update')" @click="authorityAssignHandle(scope.row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- 弹窗, 权限分配 -->
      <role-authority v-if="authorityAssignVisible" ref="authorityAssign" @close="closeCustomDialogHandle"></role-authority>
      <!--<role-user-view v-if="roleUserViewVisible" ref="roleUserView" @close="roleUserViewCloseDialogHandle" ></role-user-view>-->
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './role-add-or-update'
// import RoleUserView from './role-user-view'
import RoleAuthority from './role-authority'

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/sys/role/page',
        getDataListIsPage: true,
        deleteURL: '/sys/role',
      },
      dataForm: {
        name: ''
      },
      // roleUserViewVisible: false
      authorityAssignVisible: false
    }
  },
  components: {
    AddOrUpdate,
    RoleAuthority,
    // RoleUserView
  },
  created () {
    this.$nextTick(() => {
      // this.showTips()
    })
  },
  methods: {
    // 显示提示信息
    showTips () {
      this.$notify({
        title: '提示',
        message: '角色即用户组，既能用于分配功能权限，又能用于流程审批。'
      });
    },
    // 分配权限
    authorityAssignHandle (id) {
      this.authorityAssignVisible = true
      this.$nextTick(() => {
        this.$refs.authorityAssign.dataForm.roleId = id
        this.$refs.authorityAssign.init()
      })
    },
    closeCustomDialogHandle () {
      this.authorityAssignVisible = false
    },
    // roleUserViewCloseDialogHandle () {
    //   this.roleUserViewVisible = false
    // },
    // roleUserViewHandle (roleId) {
    //   this.roleUserViewVisible = true
    //   this.$nextTick(() => {
    //     this.$refs.roleUserView.dataForm.roleId = roleId
    //     this.$refs.roleUserView.init()
    //   })
    // }
  }
}
</script>
