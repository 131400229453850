<template>
  <el-card shadow="never" class="aui-card--fill" ref="element">
    <div>
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.typeId" clearable class="w-percent-100">
            <el-option value="1" label="设计"></el-option>
            <el-option value="2" label="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.otherContractName" placeholder="合同名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList"></toolbar-button>
            <toolbar-button role="print" @click="openPrint">打印</toolbar-button>
            <toolbar-button role="export" @click="openExport">导出</toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <!-- <el-row :gutter="10">
        <el-col :span="24">
          <h1 class="my-report-title">合同支付台账</h1>
        </el-col>
      </el-row> -->
      <vxe-table
          border
          resizable
          show-overflow
          ref="tableRef"
          :data="dataList"
          :loading="dataListLoading"
          :height="tableHeight"
          :row-config="{isCurrent: true, isHover: true}"
          :print-config="{}"
          :export-config="{}">
        <vxe-column type="seq" title="序号" width="60" align="center"></vxe-column>
        <vxe-column field="code" title="合同编号" width="160"></vxe-column>
        <vxe-column field="name" title="合同名称"></vxe-column>
        <vxe-column field="secondParty" title="乙方" width="200"></vxe-column>
        <vxe-column field="firstParty" title="甲方" width="200"></vxe-column>
        <vxe-column field="typeName" title="合同类型" width="90" align="center"></vxe-column>
        <vxe-column field="signDate" title="签订日期" width="100" align="center"></vxe-column>
        <vxe-column field="cost" title="合同金额(元)" width="140" align="right" :formatter="amountFormatter"></vxe-column>
        <vxe-column field="payAmountTotal" title="累计支付(元)" width="140" align="right" fixed="right" :formatter="amountFormatter"></vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>
<!--其他合同支付台账-->
<script>
import mixinViewModule from '@/mixins/view-module'
import XEUtils from "xe-utils";

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/otherContract/report/list',
        getDataListIsPage: false,
      },
      dataForm: {
        prjId: '',
        otherContractName: '',
        typeId: ''
      },
      tableHeight: 400,

      // 打印顶部内容模板
      printTopHtml: `<h1 class="my-report-title">合同支付台账</h1>`,
      printConfig: {
        // 自定义打印的样式示例
        style: `.my-report-title {
                  text-align: center;
                }
                `,
        // 输出数据的方式列表 current:当前页数据、selected:选中数据、all:全量（所有页数据）
        modes: ['current'],
        // 打印之前
        beforePrintMethod: ({content}) => {
          // 拦截打印之前，返回自定义的 html 内容
          return this.printTopHtml + content
        }
      },
      // vxeTable 导出配置
      exportConfig: {
        modes: ['current']
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 220
    })
  },
  methods: {
    // 项目切换事件
    prjChangeHandle() {
      this.getDataList()
    },
    // vxeTable打印报表
    openPrint() {
      this.$refs.tableRef.openPrint(this.printConfig)
    },
    // 金额格式化
    amountFormatter({cellValue}) {
      return XEUtils.commafy(cellValue, {digits: 2})
    },
    // vxeTable高级导出
    openExport() {
      this.$refs.tableRef.openExport(this.exportConfig)
    }
  }
}
</script>
<style lang="scss" scoped>
.my-report-title {
  text-align: center;
}
</style>