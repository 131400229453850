<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__wbs">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <contract-cascader ref="contractCascader" include-sub @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button @click="quoteTemplate()" name="引用模板" icon="el-icon-paperclip" v-if="$hasPermission('mps:wbs:save')"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:wbs:save')" @click="addChildNode({id: 0})"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:wbs:delete')" @click="myDeleteHandle()"></toolbar-button>
            <toolbar-button role="import" v-if="$hasPermission('mps:wbs:import')" @click="importHandle"></toolbar-button>
            <!-- <toolbar-button role="export" v-if="$hasPermission('mps:wbs:export')" @click="exportHandle"></toolbar-button> -->
          </el-button-group>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <el-tooltip content="刷新名称路径" placement="top" effect="light">
              <el-button icon="el-icon-refresh" v-if="$hasPermission('mps:wbs:update')" @click="refreshHandle"></el-button>
            </el-tooltip>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
          v-if="tableShow"
          ref="wbsTable"
          v-loading="tableLoading"
          :data="tableData"
          row-key="id"
          lazy
          @row-click="rowClick"
          :load="loadTableData"
          @selection-change="dataListSelectionChangeHandle"
          border
          highlight-current-row
          :height="wbsTableHeight"
          style="width: 100%;"
          :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="220" show-overflow-tooltip></el-table-column>
        <el-table-column prop="code" label="编号" min-width="150" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="unitName" label="单位" align="center" width="80"></el-table-column>
        <el-table-column prop="num" label="工程量" align="right" width="100"></el-table-column> -->
        <!-- <el-table-column prop="stakeNo" label="桩号" min-width="120" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="levelId" label="层级" width="100" align="center">
          <template v-slot="{row}">
            <el-tag v-if="row.levelId == 1">单位工程</el-tag>
            <el-tag v-if="row.levelId == 2">子单位工程</el-tag>
            <el-tag v-if="row.levelId == 3">分部工程</el-tag>
            <el-tag v-if="row.levelId == 4">子分部工程</el-tag>
            <el-tag v-if="row.levelId == 5">分项工程</el-tag>
            <el-tag v-if="row.levelId == 6">子分项工程</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="wbsTypeId" label="类型" width="100" align="center">
          <template v-slot="{row}">
            <!--<el-tag v-if="row.wbsTypeId == 'UNKNOWN'" type="info">未知类型</el-tag>-->
            <el-tag v-if="row.wbsTypeId == 'SUBGRADE'">路基工程</el-tag>
            <el-tag v-if="row.wbsTypeId == 'PAVEMENT'">路面工程</el-tag>
            <el-tag v-if="row.wbsTypeId == 'BRIDGE'">桥梁工程</el-tag>
            <el-tag v-if="row.wbsTypeId == 'CULVERT'">涵洞工程</el-tag>
            <el-tag v-if="row.wbsTypeId == 'ELECTRICAL'">机电工程</el-tag>
            <el-tag v-if="row.wbsTypeId == 'ENV'">环保工程</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="质保资料" show-overflow-tooltip align="center">
          <el-table-column label="开工报告" width="80" align="center" show-overflow-tooltip>
            <template v-slot="{row}">
              <el-tooltip v-if="row.qaFileState >= 1" class="item oss-tooltip" effect="light" content="开工报告已上传" placement="bottom">
                <i class="el-icon-check" style="color: green; cursor: pointer;" @click="openQualityFileDialog(row, '1')"></i>
              </el-tooltip>
              <el-tooltip v-else-if="row.isLeaf" class="item oss-tooltip" effect="light" content="开工报告未上传" placement="bottom">
                <i class="el-icon-close" style="color: red; cursor: pointer;" @click="openQualityFileDialog(row, '1')"></i>
              </el-tooltip>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column label="检验申请批复" width="100" align="center" show-overflow-tooltip>
            <template v-slot="{row}">
              <el-tooltip v-if="row.qaFileState >= 2" class="item oss-tooltip" effect="light" content="检验申请批复已上传" placement="bottom">
                <i class="el-icon-check" style="color: green; cursor: pointer;" @click="openQualityFileDialog(row, '2')"></i>
              </el-tooltip>
              <el-tooltip v-else-if="row.isLeaf" class="item oss-tooltip" effect="light" content="检验申请批复未上传" placement="bottom">
                <i class="el-icon-close" style="color: red; cursor: pointer;" @click="openQualityFileDialog(row, '2')"></i>
              </el-tooltip>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column label="交工证书" width="80" align="center" show-overflow-tooltip>
            <template v-slot="{row}">
              <el-tooltip v-if="row.qaFileState >= 3" class="item oss-tooltip" effect="light" content="交工证书已上传" placement="bottom">
                <i class="el-icon-check" style="color: green; cursor: pointer;" @click="openQualityFileDialog(row, '3')"></i>
              </el-tooltip>
              <el-tooltip v-else-if="row.isLeaf" class="item oss-tooltip" effect="light" content="交工证书未上传" placement="bottom">
                <i class="el-icon-close" style="color: red; cursor: pointer;" @click="openQualityFileDialog(row, '3')"></i>
              </el-tooltip>
              <span v-else></span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="300">
          <template v-slot="{row}">
            <table-button role="view" @click="viewHandle(row.id)"></table-button>
            <table-button role="edit" v-if="$hasPermission('mps:wbs:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="addChild" v-if="$hasPermission('mps:wbs:save')" @click="addChildNode(row)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:wbs:delete')" @click="myDeleteHandle(row.id, row.pid)"></table-button>
            <el-dropdown @command="(command) => handleCommand(command, row)" :show-timeout="100">
              <i class="el-icon-arrow-down el-icon--right"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-show="$hasPermission('mps:wbs:update')" command="u">上移</el-dropdown-item>
                <el-dropdown-item v-show="$hasPermission('mps:wbs:update')" command="d">下移</el-dropdown-item>
                <el-dropdown-item v-show="$hasPermission('mps:wbs:update')" command="c">复制属性</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column label="附件" fixed="right" align="center" width="70">
          <template v-slot="{row}">
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:wbs:update'), row.name)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹窗, 新增 / 修改-->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @close="closeDialogHandle" @addNode="addNodeCallback" @updateNode="updateNodeCallback"></add-or-update>
      <!-- 弹窗, 查看-->
      <wbs-view v-if="viewVisible" ref="view" @close="closeDialogHandle"></wbs-view>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getTableData()" url="/mps/wbs/import" thumbnail="import_wbs.jpg"></excel-import>
      <!-- 引用模板选择弹窗 -->
      <quote v-if="quoteVisible" ref="quote" @close="closeQuoteHandle" @refreshDataList="getTableData()"></quote>
      <!-- 质检资料弹窗 -->
      <quality-file v-if="qualityFileVisible" ref="qualityFile" @close="closeQualityFile"></quality-file>
      <!-- wbs多选弹窗（复制内容）-->
      <wbs-copy-properties v-if="copyPropertiesVisible" ref="copyProperties" @submit="copyPropertiesSubmit"></wbs-copy-properties>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './wbs-add-or-update'
import WbsView from './wbs-view'
import Contract from "@/views/modules/mps/contract"
import Quote from './wbs-quote'
import debounce from "lodash/debounce"
import QualityFile from './wbs-quality-file'
import WbsCopyProperties  from './wbs-copy-properties'
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        deleteURL: '/mps/wbs',
        deleteSuccessCallback: this.deleteSuccessCallback,
        exportURL: `/mps/wbs/export`
      },
      dataForm: {
        prjId: '',
        contractId: '',
        subcontractorId: '',
      },
      addOrUpdateVisible: false,
      viewVisible: false,
      quoteVisible: false,
      treeLoading: false,
      treeProps: {
        children: 'children',
        label: 'name',
      },
      // 存储已加载的节点的map
      treeNodeMap: new Map(),
      tableLoading: false,
      tableData: [],
      tableShow: true,
      wbsTableHeight: 400,
      selectedWbsId: '',
      wbsIsLeaf: false,
      hasSubcontractor: true,
      qualityFileVisible: false,
      copyPropertiesVisible: false,
      // 当前打开的质检资料弹窗的行，用于关闭弹窗后更新这一行的质检资料状态
      currentQualityFileRow: null,
      // 复制的源id
      copySourceId: '',
    }
  },
  components: {Contract, AddOrUpdate, WbsView, Quote, QualityFile, WbsCopyProperties},
  mounted() {
    this.$nextTick(() => {
      this.wbsTableHeight = window.innerHeight - 180
    })
  },
  methods: {
    // el-table懒加载节点
    loadTableData(data, node, resolve) {
      let pid = data.id
      // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
      this.treeNodeMap.set(pid, {data, node, resolve})
      this.$http.get('/mps/wbs/children', {
            params: {
              'pid': pid,
              ...this.dataForm
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data);
        // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
        // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
        if (res.data.length == 0) {
          this.$set(this.$refs.wbsTable.store.states.lazyTreeNodeMap, pid, [])
        }
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    closeDialogHandle() {
      this.viewVisible = false
      this.addOrUpdateVisible = false
    },
    // 质检资料弹窗关闭
    closeQualityFile() {
      this.qualityFileVisible = false
      // 更新这一行的质检资料状态
      let id = this.currentQualityFileRow.id
      this.$http.get(`/mps/wbs/${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.currentQualityFileRow.qaFileState = res.data.qaFileState
      }).catch(() => {
      })
    },
    // 合同标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.hasSubcontractor = data.contract.hasSubcontractor
      this.selectedWbsId = ''
      this.getTableData()
    },
    getTableData() {
      this.treeNodeMap = new Map()
      // 重置一些el-table缓存的变量
      // 此处如果不重置，可能会导致一些无法预料的情况
      // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      this.tableLoading = true
      this.$http.get('/mps/wbs/children', {
            params: {
              pid: 0,
              ...this.dataForm
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.tableData = res.data
        this.tableLoading = false
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    addNodeCallback(pid) {
      this.refreshParentNode(pid)
    },
    updateNodeCallback(pid, isPidChanged) {
      if (isPidChanged) {
        // 父节点修改，直接刷新列表
        this.getTableData()
      } else {
        // 父节点未修改，刷新该节点子节点列表
        this.refreshParentNode(pid)
      }
    },
    // 刷新父节点（只能刷新展开过的节点）
    refreshParentNode(pid) {
      let nodeInfo = this.treeNodeMap.get(pid)
      if (nodeInfo) {
        // 这个loading属性控制此节点在load时是否重新加载
        // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
        nodeInfo.node.loading = true
        nodeInfo.data.hasChildren = true // 设置父节点为非叶子节点，出现展开按钮
        this.loadTableData(nodeInfo.data, nodeInfo.node, nodeInfo.resolve)
      } else {
        this.getTableData()
      }
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.addOrUpdate.init()
      })
    },
    // 新增wbs子节点
    async addChildNode(row) {
      // 最末级节点新增子节点时，先判断该节点是否已经有分项清单，有则不允许新增子节点
      if (row.isLeaf) {
        try {
          let {data: res} = await this.$http.get(`/mps/subBoq/isSubItemExist/${row.id}`)
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (res.data) {
            return this.$message.error('该分项下存在分项清单，无法添加下级节点。')
          }
        } catch (error) {
          return this.$message.error('出错了')
        }
      }
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.addOrUpdate.init()
      })
    },
    deleteSuccessCallback({id,pid}) {
      if (id && pid) {
        this.refreshParentNode(pid)
      } else {
        this.getTableData()
      }
    },
    // 删除 wbs节点
    myDeleteHandle(id, pid) {
      this.deleteHandle(id,
          {
            deleteSuccessCallbackArgs: {id, pid},
            promptMessage: '同时会删除下级节点',
            promptTitle: '确定进行[删除]操作?',
            autoQuery: false
          }
      )
    },
    // 上移（即：前移）
    moveBackward(row) {
      this.$http.post('/mps/wbs/moveBackward', row).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.refreshParentNode(row.pid)
        this.$message.success('移动成功。')
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    // 下移（即：后移）
    moveForward(row) {
      this.$http.post('/mps/wbs/moveForward', row).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.refreshParentNode(row.pid)
        this.$message.success('移动成功。')
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    closeQuoteHandle() {
      this.quoteVisible = false
    },
    // 引用wbs模板
    quoteTemplate: debounce(function () {
      // 如果此标段工区已有计量，则不允许引用模板
      this.$http.get('/mps/meas/list', {
            params: {
              contractId: this.dataForm.contractId,
              subcontractorId: this.dataForm.subcontractorId,
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data.length > 0) {
          this.$message.error('该标段已开始计量，无法重新引用。')
        } else {
          // 提示覆盖
          this.$confirm('重新引用模板数据将自动清空原有分部分项工程,是否继续？', '警告', {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(() => {
            this.quoteVisible = true
            this.$nextTick(() => {
              this.$refs.quote.contractId = this.dataForm.contractId
              this.$refs.quote.subcontractorId = this.dataForm.subcontractorId
              this.$refs.quote.init()
            })
          }).catch(e => e)
        }
      }).catch(() => {
        return this.$message.error('出错了')
      })
    }, 1000, {'leading': true, 'trailing': false}),
    handleCommand(command, row) {
      switch (command) {
        case 'u':
          this.moveBackward(row)
          break;
        case 'd':
          this.moveForward(row)
          break;
        case 'c':
          this.copyProperties(row)
          break;
      }
    },
    // 将当前行的形象单元、数量、单位、类型、构件属性，复制到弹窗中选择的其他wbs上
    copyProperties(row) {
      this.copySourceId = row.id
      this.copyPropertiesVisible = true
      this.$nextTick(() => {
        this.$refs.copyProperties.dataForm.disabledId = row.id
        this.$refs.copyProperties.dataForm.contractId = this.dataForm.contractId
        this.$refs.copyProperties.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.copyProperties.init()
      })
    },
    copyPropertiesSubmit(rows) {
      if (rows.length != 0) {
        const loading = this.$loading({
          lock: true,
          text: '复制中...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let targetIds = rows.map(item => item.id)
        this.$http['post']('/mps/wbs/copyProperties', {sourceId: this.copySourceId, targetIds: targetIds}).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {}
          })
        }).catch(() => {
          // do nothing
        }).finally(() => {
          loading.close()
        })
      }
    },
    rowClick(row) {
      this.selectedWbsId = row.id
      this.wbsIsLeaf = row.isLeaf
      //this.getBoqData(row.id)
    },

    // 刷新节点的名称路径(pids,pnames)
    refreshHandle() {
      this.$confirm('此操作将刷新分部分项的名称路径, 是否继续?').then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.get(`/mps/wbs/redressNamePath`,
            {
              params: {
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code != 0) {
            return this.$alert(res.msg, '', {
              confirmButtonText: '关闭',
              type: "error"
            })
          }
          this.$alert('已全部刷新！', '', {
            confirmButtonText: '关闭',
            type: "success"
          })
        }).finally(() => {
          loading.close()
        })
      }).catch()
    },
    // 打开质检资料弹窗
    openQualityFileDialog(row, type) {
      this.currentQualityFileRow = row
      this.qualityFileVisible = true
      this.$nextTick(() => {
        this.$refs.qualityFile.dataForm.wbsId = row.id
        this.$refs.qualityFile.activeTabName = type
        if (this.$hasPermission('mps:wbs:update')) {
          this.$refs.qualityFile.canEdit = true
        } else {
          this.$refs.qualityFile.canEdit = false
        }
        this.$refs.qualityFile.init()
      })
    }
  }
}
</script>